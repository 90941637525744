import { SUPPORT_EMAIL, SUPPORT_TELEGRAM } from 'shared/common/constants';
import { routes } from 'shared/common/routes';

export const questions = [
  {
    questions: [
      {
        answer:
          'Просто зарегистрируйтесь и задавайте вопросы экспертам. \n\nЕсли хотите оказывать консультации, почитайте страницу <a href="https://www.consulty.online/expert">Стать экспертом</a>.',
        question: 'Как начать пользоваться Consulty?',
      },

      {
        answer: `Во вкладке <a href="${routes.topics}">Найти эксперта</a> выберите эксперта в подходящей теме и договоритесь с ним о консультации. Или задайте в этой теме вопрос и выбирайте эксперта по откликам на него <a href="${routes.responses}">во вкладке Отклики</a> вашего мессенджера 💬`,
        question: 'Как заказать консультацию?',
      },

      {
        answer:
          'Обращайте внимание на рейтинг эксперта и отзывы о нем. Посмотрите видеовизитку, зайдите на страницы его соцсетей, если он их разместил. Если не можете выбрать между несколькими экспертами, задайте каждому вопрос и закажите консультацию у того, чей ответ вам больше понравился 👍 Но это работает только с экспертами, у которых включен бесплатный чат.',
        question: 'Как выбрать эксперта?',
      },
      {
        answer: 'Все отзывы реальные. Оставить отзыв эксперту можно только после платной консультации на Consulty.',
        question: 'Отзывы об экспертах настоящие?',
      },

      {
        answer:
          'Консультация закончится через 1 час или после того, как эксперт напишет 20 сообщений.\n\nЛюбую консультацию можно продлить платно или бесплатно. Если эксперт не успел помочь вам в рамках одной консультации, договоритесь о бесплатном продлении. Если у вас появились дополнительные вопросы, продлите консультацию платно.',
        question: 'Сколько длится консультация?',
      },

      {
        answer:
          'Если вы оцените работу эксперта на 1 или 2 звезды из 5 — мы вернем вам полную стоимость консультации. Делаем это в течение двух часов в часы работы службы поддержки. Если возврат средств потребуется глубокой ночью, придется немного подождать до утра. Надеемся на ваше понимание.',
        question: 'Можно вернуть деньги за консультацию, которая не понравилась?',
      },

      {
        answer:
          'Получить консультацию может гражданин любой страны. Стать экспертом Consulty пока только гражданин РФ. ',
        question: 'Сервис только для граждан РФ?',
      },

      {
        answer:
          'Бывают. Некоторых эксперты консультируют за специальные жетоны. Их можно получить по промокодам, которые выдают наши партнеры и сами эксперты.\n\nЖетоны принимают эксперты, у которых в профиле есть иконка жетона рядом с названием консультации и ее ценой.',
        question: 'Бесплатные консультации бывают в Consulty?',
      },
      {
        answer: `Вы можете предложить свою тему! Напишите нам в <a href="https://t.me/${SUPPORT_TELEGRAM}">Telegram</a> или на <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>. Можете также воспользоваться специальной формой на <a href="${routes.topics}">странице Тем</a>, она там в самом низу.`,
        question: 'Почему для консультаций открыта только тема Бизнеса?',
      },
    ],
  },
];
