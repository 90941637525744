import React, { memo } from 'react';
import { Modal } from 'routes/landing/components/Modal';
import styled from 'styled-components';

const THEMES: string[] = [
  'Спорт',
  'Красота',
  'Выбор техники',
  'Блоггинг',
  'Страны и путешествия',
  'Астрология',
  'IT',
  'Киберспорт',
  'Образование',
  'Косметология',
  'Психология',
  'Здоровье',
  'Финансы',
  'Авто',
  'Питомцы и животные',
  'Налоги и бухгалтерия',
  'Недвижимость',
  'Дома и квартиры',
  'Дети',
  'Продукты питания',
  'Сад и огород',
  'Хобби и рукоделие',
  'Мамочкам',
];

type ICategoryModalProps = {
  show: boolean;
  onHide: () => void;
};

export const CategoryModal = memo<ICategoryModalProps>(({ show, onHide }) => {
  const components = THEMES.map((theme, index) => (
    <StyledTheme key={index}>
      <BeforeStyle>-</BeforeStyle>
      <StyledLi>{theme}</StyledLi>
    </StyledTheme>
  ));
  return (
    <Modal show={show} onHide={onHide}>
      <StyledContainer>
        <StyledTitle>Новые темы запустим в 2022 году</StyledTitle>
        <StyledContent>{components}</StyledContent>
        <StyleButton onClick={() => onHide()}>Отлично</StyleButton>
      </StyledContainer>
    </Modal>
  );
});

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '25px 20px',
});

const StyledTitle = styled.span({
  fontSize: 25,
  fontWeight: 'bold',
  color: '#232832',
  marginBottom: '10px',
  lineHeight: '1.05',
  letterSpacing: 'normal',
});

const StyledContent = styled.ul({
  marginBottom: 30,
});

const StyledTheme = styled.div({
  display: 'flex',
  gap: 10,
});

const StyledLi = styled.li({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: '10px',

  // '&:after': {
  //     display: 'block',
  //     height: 2,
  //     width: 5,
  //     color: '#8b33ff',
  //     content: ''
  // }
});

const BeforeStyle = styled.div({
  display: 'block',
  height: 2,
  width: 5,
  color: '#8b33ff',
});

const StyleButton = styled.button({
  width: '100%',
  height: 50,
  borderRadius: '12px',
  border: 'solid 0.5px #8b33ff;',
  backgroundColor: '#fff',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#8b33ff',
    color: '#fff',
  },
});
