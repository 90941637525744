import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../constants/breakpoints';
import type { BlockType } from './types';

const StyledWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [CONDITION_DESKTOP]: {
    position: 'relative',
    height: 483,
  },
});

const StyledMessages = styled.img({
  width: '100%',
  maxWidth: 320,
  marginBottom: 20,

  [CONDITION_DESKTOP]: {
    display: 'none',
  },
});

const StyledMessagesDesktop = styled.img({
  display: 'none',

  [CONDITION_DESKTOP]: {
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    width: 525,
    height: 360,
    marginBottom: 0,
  },
});

const StyledMain = styled.img({
  width: '100%',
  maxWidth: 280,

  [CONDITION_DESKTOP]: {
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    right: 220,
    maxWidth: 479,
  },
});

type ImagesProps = {
  block: BlockType;
};

export const Images: FC<ImagesProps> = ({ block: { imageDesktop, imageMobile } }) => (
  <StyledWrapper>
    <StyledMessages src={imageMobile} alt="" />

    <StyledMessagesDesktop src={imageDesktop} alt="" />

    <StyledMain src="/landing/main/promo-main.png" alt="" />
  </StyledWrapper>
);
