import React, { memo } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div({
  display: 'inline-block',
  paddingLeft: 5,
  paddingRight: 5,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: '#8b33ff',
  color: '#8b33ff',
  borderRadius: 1,

  fontSize: '12px',
  lineHeight: '16px',
  fontStretch: 'normal',
  fontStyle: 'normal',
});

export const ComingSoon = memo(() => <StyledWrapper>Скоро</StyledWrapper>);
