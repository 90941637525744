import type { ReactNode } from 'react';
import React, { memo } from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP, CONDITION_RETINA } from '../constants/breakpoints';
import { containImageStyle } from '../utils/containImageStyle';

type StyledWrapperProps = {
  $bgColor: string;
};

const StyledWrapper = styled.div<StyledWrapperProps>(({ $bgColor }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: $bgColor,
  borderRadius: 12,
  paddingTop: 30,
  paddingLeft: 30,
  paddingRight: 30,
  paddingBottom: 282,

  [CONDITION_DESKTOP]: {
    paddingTop: 40,
    paddingLeft: 40,
    paddingRight: 640,
    paddingBottom: 66,
  },
}));

const StyledTitleBlock = styled.div({
  marginBottom: 20,

  [CONDITION_DESKTOP]: {
    marginBottom: 30,
  },
});

const StyledTitle = styled.div({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: 1.2,
  color: '#232832',

  [CONDITION_DESKTOP]: {
    fontSize: 26,
  },
});

const StyledSubTitle = styled.div({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1.43,
  color: '#232832',
  marginTop: 10,

  [CONDITION_DESKTOP]: {
    fontSize: 17,
    marginTop: 20,
  },
});

const StyledButtonWrapper = styled.div({
  marginTop: 40,
  display: 'flex',
});

const StyledButtonWrapperBlock = styled.div({
  display: 'flex',
  minWidth: 182,
});

type StyledBubbleProps = {
  $bubbleImage: string;
  $bubbleImageRetina?: string;
};

const StyledBubble = styled.div<StyledBubbleProps>(({ $bubbleImage, $bubbleImageRetina }) => ({
  position: 'absolute',
  right: 109,
  bottom: 150,
  width: 172,
  height: 100,
  ...containImageStyle($bubbleImage),

  [CONDITION_RETINA]: $bubbleImageRetina
    ? {
        backgroundImage: `url(${$bubbleImageRetina})`,
      }
    : undefined,

  [CONDITION_DESKTOP]: {
    right: 326,
    bottom: 141,
    width: 273,
    height: 159,
  },
}));

type StyledMainImageProps = {
  $mainImageDesktop: string;
  $mainImageMobile: string;
};

const StyledMainImage = styled.div<StyledMainImageProps>(({ $mainImageDesktop, $mainImageMobile }) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  borderRadius: 12,
  width: 300,
  height: 229,
  ...containImageStyle($mainImageMobile),

  [CONDITION_DESKTOP]: {
    width: 550,
    height: '100%',
    backgroundImage: `url(${$mainImageDesktop})`,
    backgroundSize: 'cover',
  },
}));

type BannerBaseProps = {
  title: ReactNode;
  subTitle?: ReactNode;
  actions?: ReactNode;
  mainImageDesktop: string;
  mainImageMobile: string;
  bubbleImage?: string;
  bubbleImageRetina?: string;
  bgColor: string;
  children: ReactNode;
};

export const BannerBase = memo<BannerBaseProps>(
  ({
    title,
    subTitle,
    actions,
    mainImageDesktop,
    mainImageMobile,
    bubbleImage,
    bubbleImageRetina,
    bgColor,
    children,
  }) => (
    <StyledWrapper $bgColor={bgColor}>
      <StyledMainImage $mainImageDesktop={mainImageDesktop} $mainImageMobile={mainImageMobile} />

      <StyledTitleBlock>
        <StyledTitle>{title}</StyledTitle>

        {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
      </StyledTitleBlock>

      <div>{children}</div>

      {actions && (
        <StyledButtonWrapper>
          <StyledButtonWrapperBlock>{actions}</StyledButtonWrapperBlock>
        </StyledButtonWrapper>
      )}

      {bubbleImage && <StyledBubble $bubbleImage={bubbleImage} $bubbleImageRetina={bubbleImageRetina} />}
    </StyledWrapper>
  ),
);
