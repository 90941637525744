import type { FC } from 'react';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../../constants/breakpoints';
import { ConsultationType } from './types';

const StyledWrapper = styled.div({
  '& + &': {
    marginTop: 30,
  },

  [CONDITION_DESKTOP]: {
    '& + &': {
      marginTop: 36,
    },
  },
});

const StyledName = styled.a({
  fontSize: 16,
  lineHeight: 1.25,
  fontWeight: 500,
  color: '#232832',
  marginBottom: 8,
  cursor: 'pointer',

  [CONDITION_DESKTOP]: {
    fontSize: 17,
    lineHeight: '22px',
  },

  '&:hover': {
    color: '#8B33FF',
  },
});

const StyledAncestors = styled.div({
  fontSize: 11,
  lineHeight: 1.36,
  fontWeight: 500,
  color: '#93969d',

  [CONDITION_DESKTOP]: {
    fontSize: 13,
    lineHeight: 1.15,
  },
});

type ConsultationProps = {
  consultation: ConsultationType;
};

export const Consultation: FC<ConsultationProps> = ({ consultation: { name, href, ancestors } }) => (
  <StyledWrapper>
    <StyledName href={href}>{name}</StyledName>

    <StyledAncestors>
      {ancestors.map(({ name: ancestorName }, index) => (
        <Fragment key={index}>
          {ancestorName}

          {index < ancestors.length - 1 && ' > '}
        </Fragment>
      ))}
    </StyledAncestors>
  </StyledWrapper>
);
