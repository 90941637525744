import React, { memo } from 'react';
import Typed from 'react-typed';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../constants/breakpoints';

const texts = [
  'по SMM',
  'по продажам',
  'по интернет-маркетингу',
  'по запуску стартапов',
  'по налогам',
  'по участию в тендерах',
  'по SEO-продвижению',
  'по PR',
  'по интернет-торговле',
  'по лизингу',
  'по привлечению инвестиций',
  'по подбору персонала',
  'по контент-маркетингу',
  'по судебным делам',
  'по CRM системам',
  'по трудовому праву',
  'по оформлению патентов',
  'по разработке сайтов',
  'по программам лояльности',
  'по контекстной рекламе',
];

const StyledWrapper = styled.div({
  position: 'relative',

  fontSize: 25,
  fontWeight: 700,
  lineHeight: '30px',
  color: '#ffffff',
  marginBottom: 30,
  minHeight: 30,

  [CONDITION_DESKTOP]: {
    fontSize: 40,
    lineHeight: '48px',
    minHeight: 48,
  },

  '.typed-cursor': {
    fontWeight: 300,
  },
});

export const Texts = memo(() => (
  <StyledWrapper>
    <Typed strings={texts} typeSpeed={60} backDelay={3000} backSpeed={30} />
  </StyledWrapper>
));
