import React, { memo } from 'react';
import styled from 'styled-components';

import { AnimatedInit } from '../../../components/AnimatedInit';
import { Container } from '../../../components/Container';
import { CONDITION_DESKTOP } from '../../../constants/breakpoints';
import { Item } from './Item';

const StyledContent = styled.div({
  paddingTop: 50,
  paddingBottom: 66,

  [CONDITION_DESKTOP]: {
    paddingTop: 40,
    paddingBottom: 145,
  },
});

const StyledTitle = styled.div({
  fontSize: 24,
  lineHeight: '30px',
  fontWeight: 'bold',
  color: '#232832',
  marginBottom: 40,
  textAlign: 'center',

  [CONDITION_DESKTOP]: {
    fontSize: 40,
    lineHeight: '45px',
    marginBottom: 72,
  },
});

const StyledList = styled.div({
  [CONDITION_DESKTOP]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 20,
  },
});

const StyledAnimatedInit = styled(AnimatedInit)({
  '& + &': {
    marginTop: 64,
  },

  [CONDITION_DESKTOP]: {
    '& + &': {
      marginTop: 0,
    },
  },
});

const title = 'Гарантии сервиса';

export const Guarantee = memo(() => (
  <Container>
    <StyledContent>
      <StyledTitle>{title}</StyledTitle>

      <StyledList>
        <StyledAnimatedInit>
          <Item
            image="/landing/main/garant-1.svg"
            title="Проверенные эксперты"
            description="Эксперты проходят регулярную оценку качества, чтобы вы были довольны"
          />
        </StyledAnimatedInit>

        <StyledAnimatedInit delayDesktop={0.2}>
          <Item
            image="/landing/main/garant-2.svg"
            title="Достоверные отзывы"
            description="Эксперты получают отзывы только за реальные консультации"
          />
        </StyledAnimatedInit>

        <StyledAnimatedInit delayDesktop={0.4}>
          <Item
            image="/landing/main/garant-3.svg"
            title="Гарантия качества"
            description="Вернем деньги, если вас не устроит консультация"
          />
        </StyledAnimatedInit>
      </StyledList>
    </StyledContent>
  </Container>
));
