import type { ReactNode } from 'react';
import React, { memo, useCallback, useEffect, useRef,useState } from 'react';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { CONDITION_DESKTOP } from '../../../constants/breakpoints';
import { loadImage } from '../../../utils/loadImage';

const StyledWrapper = styled.div({
  position: 'relative',
  paddingTop: 230,

  [CONDITION_DESKTOP]: {
    paddingTop: 0,
  },
});

const StyledContent = styled.div({
  position: 'relative',
  zIndex: 1,
});

const StyledMessageWrapper = styled.div({
  position: 'relative',
  width: 240,
  height: 291,
  marginLeft: 'auto',
  marginRight: 'auto',

  [CONDITION_DESKTOP]: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 20,
  },
});

const StyledMessageImage = styled.div({
  width: 240,
  height: 291,
  backgroundSize: 'contain',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
  marginBottom: 20,
  transition: 'opacity 0.8s',
});

const StyledButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',

  [CONDITION_DESKTOP]: {
    paddingLeft: 15,
    justifyContent: 'flex-start',
  },
});

const StyledPeopleImage = styled.img({
  position: 'absolute',
  top: 0,
  left: '50%',
  marginLeft: -140,
  width: 280,
  height: 280,
  transition: 'opacity 0.8s',

  [CONDITION_DESKTOP]: {
    top: -30,
    left: 130,
    width: 392,
    height: 392,
    marginLeft: 0,
  },
});

export const MessagesExample = memo(() => {
  useEffect(() => {
    for (let i = 0; i < 7; ++i) {
      loadImage(`/landing/main-top-block/pic${i + 1}.png`);
      loadImage(`/landing/main-top-block/cons1-${i + 1}.png`);
    }
  }, []);

  const [[currentSlide, prevCurrentSlide], setSlides] = useState<[number, number | null]>([0, null]);
  const changeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const setNextSlide = useCallback(() => {
    if (changeTimeoutRef.current) {
      clearTimeout(changeTimeoutRef.current);
    }

    setSlides(([prevSlide]) => {
      if (prevSlide < 7) {
        return [prevSlide + 1, prevSlide];
      }

      return [0, prevSlide];
    });

    changeTimeoutRef.current = setTimeout(() => {
      setNextSlide();
    }, 5000);
  }, []);

  useEffect(() => {
    changeTimeoutRef.current = setTimeout(() => {
      setNextSlide();
    }, 5000);

    return () => {
      if (changeTimeoutRef.current) {
        clearTimeout(changeTimeoutRef.current);
      }
    };
  }, []);

  const onMouseEnter = useCallback(() => {
    if (changeTimeoutRef.current) {
      clearTimeout(changeTimeoutRef.current);
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    changeTimeoutRef.current = setTimeout(() => {
      setNextSlide();
    }, 3000);
  }, []);

  const peopleImages: ReactNode[] = [
    <StyledPeopleImage src={`/landing/main-top-block/pic${currentSlide + 1}.png`} alt="" key={currentSlide} />,
  ];

  if (prevCurrentSlide !== null) {
    peopleImages.push(
      <StyledPeopleImage
        src={`/landing/main-top-block/pic${prevCurrentSlide + 1}.png`}
        alt=""
        style={{
          opacity: 0,
        }}
        key={prevCurrentSlide}
      />,
    );
  }

  const renderedMessages: ReactNode[] = [
    <StyledMessageImage
      style={{
        backgroundImage: `url(/landing/main-top-block/cons1-${currentSlide + 1}.png)`,
      }}
      key={currentSlide}
    />,
  ];

  if (prevCurrentSlide !== null) {
    renderedMessages.push(
      <StyledMessageImage
        style={{
          backgroundImage: `url(/landing/main-top-block/cons1-${prevCurrentSlide + 1}.png)`,
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0,
        }}
        key={prevCurrentSlide}
      />,
    );
  }

  return (
    <StyledWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onMouseEnter}
      onTouchEnd={onMouseLeave}
    >
      {peopleImages}

      <StyledContent>
        <StyledMessageWrapper>{renderedMessages}</StyledMessageWrapper>

        <StyledButtonWrapper>
          <Button type="button" $size="50" $color="violet-opacity" $icon="/landing/refresh.svg" onClick={setNextSlide}>
            Ещё пример
          </Button>
        </StyledButtonWrapper>
      </StyledContent>
    </StyledWrapper>
  );
});
