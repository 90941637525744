import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../../constants/breakpoints';
import { Category } from './Category';
import { CategoryType } from './types';

const StyledTitle = styled.div({
  fontSize: 21,
  fontWeight: 'bold',
  lineHeight: 1,
  color: '#232832',
  marginBottom: 30,

  [CONDITION_DESKTOP]: {
    fontSize: 25,
    lineHeight: 1.2,
    marginBottom: 40,
  },
});

const categories: CategoryType[] = [
  {
    id: 1,
    name: 'Бизнес',
    coming_soon: false,
    url: '/',
    consultations: 150,
    icon: '/landing/cup.png',
    iconRetina: '/landing/cup@2x.png',
  },

  // {
  //   id: 2,
  //   name: 'Юридические вопросы',
  //   coming_soon: true,
  //   url: '/',
  //   icon: '/landing/invalid-name.png',
  //   iconRetina: '/landing/invalid-name@2x.png',
  // },
  //
  // {
  //   id: 3,
  //   name: 'Спорт',
  //   coming_soon: true,
  //   url: '/',
  //   icon: '/landing/ball.png',
  //   iconRetina: '/landing/ball@2x.png',
  // },
  //
  // {
  //   id: 4,
  //   name: 'Дети',
  //   coming_soon: true,
  //   url: '/',
  //   icon: '/landing/ico.png',
  //   iconRetina: '/landing/ico@2x.png',
  // },

  // {
  //   id: 5,
  //   name: 'И еще 23 других темы',
  //   coming_soon: true,
  //   url: '/',
  //   icon: '/landing/chat.png',
  //   iconRetina: '/landing/chat@2x.png',
  // },
];

export const Categories: FC = () => (
  <div>
    <StyledTitle>Популярные темы</StyledTitle>

    <div>
      {categories.map((category) => (
        <Category category={category} key={category.id} />
      ))}
    </div>

    {/* <LoadMore /> */}
  </div>
);
