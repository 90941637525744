import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../../constants/breakpoints';
import { Consultation } from './Consultation';
import type { ConsultationType } from './types';

const StyledTitle = styled.div({
  fontSize: 21,
  fontWeight: 'bold',
  lineHeight: 1,
  color: '#232832',
  marginBottom: 30,

  [CONDITION_DESKTOP]: {
    fontSize: 25,
    lineHeight: 1.2,
    marginBottom: 40,
  },
});

const consultations: ConsultationType[] = [
  {
    name: 'Как лучше привлечь клиентов в мой бизнес через интернет?',
    href: 'https://www.consulty.online/app/topics/204',
    ancestors: [
      {
        name: 'Бизнес',
      },

      {
        name: 'Интернет-маркетинг',
      },

      {
        name: 'Интернет маркетинг под ключ',
      },
    ],
  },

  {
    name: 'Как сделать грамотный договор оказания услуг?',
    href: 'https://www.consulty.online/app/topics/224',
    ancestors: [
      {
        name: 'Юридические вопросы',
      },

      {
        name: 'Общая консультация',
      },
    ],
  },

  {
    name: 'Как привлекать хороших продавцов на работу?',
    href: 'https://www.consulty.online/app/topics/232',
    ancestors: [
      {
        name: 'Бизнес',
      },

      {
        name: 'Управление и развитие',
      },

      {
        name: 'Управление отделом продаж',
      },
    ],
  },

  {
    name: 'Как нам платить меньше налогов, если мы ООО и покупаем много товаров?',
    href: 'https://www.consulty.online/app/topics/248',
    ancestors: [
      {
        name: 'Бухгалтерия и налоги',
      },

      {
        name: 'Общая консультация',
      },
    ],
  },
];

export const Consultations: FC = () => (
  <div>
    <StyledTitle>Популярные консультации</StyledTitle>

    <div>
      {consultations.map((consultation, index) => (
        <Consultation consultation={consultation} key={index} />
      ))}
    </div>
  </div>
);
