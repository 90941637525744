import Head from 'next/head';
import { useRouter } from 'next/router';
import { TFunction, useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React from 'react';
import ym from 'react-yandex-metrika';
import { FirstConsultation } from 'routes/landing/banners/FirstConsultation';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { MainLayout } from 'widgets/layouts';

import { BecomeAnExpert } from '../../banners/BecomeAnExpert';
import { Container } from '../../components/Container';
import { Answers } from '../ExpertLanding/Answers';
import { HowItWorks } from '../ExpertLanding/HowItWorks';
import { CategoryModalWrapper } from './CategoryModal';
import { Experts } from './Experts';
import { Guarantee } from './Guarantee';
import { Popular } from './Popular';
import { Promo } from './Promo';
import { questions } from './questions';
import { TopBlock } from './TopBlock';

const getNavbar = (t: TFunction) => [
  {
    text: t('defaultNavbar.findExperts'),
    href: routes.topics,
  },
  {
    text: t('defaultNavbar.becomeExpert'),
    href: routes.expertLanding,
  },

  {
    text: t('defaultNavbar.aboutConsulty'),
    href: `${routes.about}`,
  },
];

export const Main: FC<unknown> = () => {
  const { t } = useTranslation('header.component');
  const router = useRouter();

  return (
    <MainLayout
      head={{ title: 'Сервис онлайн-консультаций с экспертами на любые темы - Consulty' }}
      navbar={getNavbar(t)}
      hasFooter
      isLoginButtonVisible
      hasHellobar
    >
      <Head>
        <meta name="title" content="Сервис онлайн-консультаций с экспертами на любые темы - Consulty" />
        <meta
          name="description"
          content="Consulty – простой сервис, в котором можно найти эксперта в любой области и заказать у него консультацию по вашему вопросу."
        />
        <meta name="og:title" content="Сервис онлайн-консультаций с экспертами на любые темы" />
        <meta name="og:site_name" content="www.consulty.online" />
        <meta name="og:url" content="https://www.consulty.online/" />
        <meta
          name="og:description"
          content="Consulty – простой сервис, в котором можно найти эксперта в любой теме и заказать у него консультацию по вашему вопросу: бизнес, спорт, недвижимость, красота, дети и другим темам."
        />
        <meta name="og:image" content="/img/social.jpg" />
      </Head>
      <TopBlock />
      <Popular />
      <Promo />
      <HowItWorks
        title="Как это работает?"
        description="В вашем распоряжении огромная база экспертов во всех областях — всего пара кликов отделяет вас от них."
        items={[
          {
            title: '1. Выберите эксперта',
            description:
              'Сравнивайте экспертов по рейтингу, отзывам, количеству консультаций, опыту и стоимости консультаций. Если не хотите выбирать — просто опубликуйте ваш вопрос, и эксперты откликнутся сами.',
            imageDesktop: '/landing/main/steps/step1.png',
            imageMobile: '/landing/main/steps/step1.png',
          },
          {
            title: '2. Получите консультацию',
            description:
              'Эксперт изучит ваш вопрос и даст первоклассную консультацию. Консультация длится 1 час и стоит от 50 рублей, в зависимости от уровня эксперта.',
            imageDesktop: '/landing/main/steps/step2.png',
            imageMobile: '/landing/main/steps/step2.png',
          },
          {
            title: '3. Оцените эксперта',
            description:
              'Оцените, насколько хорошо вас проконсультировали. Если вам не понравилось — сразу вернем деньги.',
            imageDesktop: '/landing/main/steps/step3.png',
            imageMobile: '/landing/main/steps/step3.png',
          },
        ]}
        buttonText="Найти эксперта"
        buttonDescription="и получить консультацию"
        onButtonClick={() => {
          if (ENVIRONMENT_TYPE === 'production') {
            ym('reachGoal', ['Main-how_it_works-choose_expert']);
            if (window && window.gtag) {
              window.gtag('event', 'Main-how_it_works-choose_expert');
            }
          }
          router.push(routes.topics);
        }}
      />
      <Experts />
      <Guarantee />
      <Container>
        <BecomeAnExpert />
      </Container>
      <Answers questions={questions} />
      {/* <MobileApp /> */}
      <Container style={{ marginBottom: '60px' }}>
        <FirstConsultation />
      </Container>
      {/* <AdverstingModalWrapper /> */}
      <CategoryModalWrapper />
    </MainLayout>
  );
};
