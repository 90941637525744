import React, { memo } from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../../constants/breakpoints';
import type { BlockType } from '../types';

type StyledWrapperProps = {
  $isActive: boolean;
};

const StyledWrapper = styled.div<StyledWrapperProps>(({ $isActive }) => ({
  boxSizing: 'border-box',
  display: 'inline-flex',
  alignItems: 'center',
  height: 45,
  verticalAlign: 'bottom',
  marginRight: 10,
  marginBottom: 15,
  paddingTop: 10,
  paddingBottom: 10,
  borderRadius: 23,
  paddingLeft: 15,
  paddingRight: 25,

  fontWeight: 500,
  fontSize: 15,
  lineHeight: '19px',
  backgroundColor: $isActive ? '#8b33ff' : '#ffffff',
  color: $isActive ? '#fff' : '#232832',
  boxShadow: $isActive ? 'none' : '0 0 15px 0 rgba(0, 0, 0, 0.1)',
  cursor: $isActive ? 'default' : 'pointer',
  transition: 'all 0.8s',

  [CONDITION_DESKTOP]: {
    marginRight: 20,
    marginBottom: 20,
  },
}));

const StyledImage = styled.img({
  width: 25,
  height: 25,
  marginRight: 10,
});

const StyledLabel = styled.span({});

type TagProps = {
  block: BlockType;
  index: number;
  activeIndex: number;

  setActiveIndex: (index: number) => void;
};

export const Tag = memo<TagProps>(
  ({
    block: { label, icon, iconRetina },

    index,
    activeIndex,
    setActiveIndex,
  }) => (
    <StyledWrapper
      onClick={() => {
        if (index !== activeIndex) {
          setActiveIndex(index);
        }
      }}
      $isActive={index === activeIndex}
    >
      <StyledImage src={icon} srcSet={`${iconRetina} 2x`} alt="" />

      <StyledLabel>{label}</StyledLabel>
    </StyledWrapper>
  ),
);
