import React, { memo } from 'react';
import styled from 'styled-components';

import { AnimatedInit } from '../../../components/AnimatedInit';
import { Container } from '../../../components/Container';
import { CONDITION_DESKTOP } from '../../../constants/breakpoints';
import { Categories } from './Categories';
import { Consultations } from './Consultations';
import { Experts } from './Experts';

const StyledWrapper = styled.div({
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderBottomColor: '#eaeaea',

  [CONDITION_DESKTOP]: {
    borderBottomWidth: 0,
  },
});

const StyledContent = styled.div({
  paddingTop: 40,
  paddingBottom: 40,

  [CONDITION_DESKTOP]: {
    display: 'flex',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#eaeaea',
    paddingTop: 80,
    paddingBottom: 90,
  },
});

const StyledCategoriesWrapper = styled.div({
  [CONDITION_DESKTOP]: {
    width: 330,
  },
});

const StyledConsultationsWrapper = styled.div({
  paddingTop: 60,

  [CONDITION_DESKTOP]: {
    paddingTop: 0,
    paddingLeft: 50,
    paddingRight: 80,
    flex: 1,
  },
});

const StyledExpertsWrapper = styled.div({
  paddingTop: 60,

  [CONDITION_DESKTOP]: {
    width: 270,
    paddingTop: 0,
  },
});

export const Popular = memo(() => (
  <StyledWrapper>
    <Container>
      <StyledContent>
        <StyledCategoriesWrapper>
          <AnimatedInit>
            <Categories />
          </AnimatedInit>
        </StyledCategoriesWrapper>

        <StyledConsultationsWrapper>
          <AnimatedInit delayDesktop={0.2}>
            <Consultations />
          </AnimatedInit>
        </StyledConsultationsWrapper>

        <StyledExpertsWrapper>
          <AnimatedInit delayDesktop={0.4}>
            <Experts />
          </AnimatedInit>
        </StyledExpertsWrapper>
      </StyledContent>
    </Container>
  </StyledWrapper>
));
