import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { ComingSoon } from '../../../../components/ComingSoon';
import { CONDITION_DESKTOP, CONDITION_RETINA } from '../../../../constants/breakpoints';
import { containImageStyle } from '../../../../utils/containImageStyle';
import { CategoryType } from './types';

const StyledWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',

  '& + &': {
    marginTop: 20,
  },

  [CONDITION_DESKTOP]: {
    '& + &': {
      marginTop: 30,
    },
  },
});

const StyledIconWrapper = styled.div({
  paddingRight: 15,
});

const StyledContent = styled.div({
  flex: 1,
});

type StyledIconProps = {
  $icon: string;
  $iconRetina?: string;
};

const StyledIcon = styled.div<StyledIconProps>(({ $icon, $iconRetina }) => ({
  width: 60,
  height: 60,
  borderRadius: 30,
  ...containImageStyle($icon),
  backgroundColor: $icon ? 'transparent' : '#eee',
  [CONDITION_RETINA]: $iconRetina
    ? {
        backgroundImage: `url(${$iconRetina})`,
      }
    : undefined,
}));

// type StyledNameProps = {
//   $isClickable: boolean;
// };

const StyledName = styled.div({
  fontSize: 17,
  fontWeight: 500,
  lineHeight: '20px',
  color: '#232832',
  marginBottom: 8,
});

/* const StyledNameLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
}); */

const StyledConsultationsWrapper = styled.div({
  fontSize: 15,
  fontWeight: 500,
  lineHeight: 1.33,
  color: '#93969d',
});

type CategoryProps = {
  category: CategoryType;
};

export const Category: FC<CategoryProps> = ({
  category: {
    name,
    coming_soon: isComingSoon,
    // url,
    consultations,
    icon,
    iconRetina,
  },
}) => (
  <StyledWrapper>
    <StyledIconWrapper>
      {icon && <StyledIcon $icon={icon} $iconRetina={iconRetina} />}
      {/*
        isComingSoon ? (
          <StyledIcon
            $icon={icon}
          />
        ) : (
          <Link
            to={url}
          >
            <StyledIcon
              $icon={icon}
            />
          </Link>
        )
        */}
    </StyledIconWrapper>

    <StyledContent>
      <StyledName>
        {name}
        {/*
          isComingSoon ? (
            name
          ) : (
            <StyledNameLink
              to={url}
            >
              {name}
            </StyledNameLink>
          )
          */}
      </StyledName>
      {isComingSoon ? (
        <div>
          <ComingSoon />
        </div>
      ) : (
        consultations && <StyledConsultationsWrapper>Более {consultations} тем</StyledConsultationsWrapper>
      )}
    </StyledContent>
  </StyledWrapper>
);
