import type { FC, ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../constants/breakpoints';

type ItemProps = {
  children: ReactNode;
};

const StyledWrapper = styled.div({
  display: 'flex',

  '& + &': {
    marginTop: 16,
  },

  [CONDITION_DESKTOP]: {
    '& + &': {
      marginTop: 10,
    },
  },
});

const StyledIconWrapper = styled.div({
  width: 15,
  marginRight: 10,

  [CONDITION_DESKTOP]: {
    marginRight: 15,
  },
});

const StyledIcon = styled.div({
  width: 15,
  height: 15,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundImage: 'url(/landing/check.svg)',
});

const StyledText = styled.div({
  flex: 1,

  fontSize: 13,
  lineHeight: '15px',
  color: '#232832',

  [CONDITION_DESKTOP]: {
    fontSize: 15,
    lineHeight: '20px',
  },
});

export const Item: FC<ItemProps> = ({ children }) => (
  <StyledWrapper>
    <StyledIconWrapper>
      <StyledIcon />
    </StyledIconWrapper>

    <StyledText>{children}</StyledText>
  </StyledWrapper>
);
