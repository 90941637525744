import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../constants/breakpoints';
import { containImageStyle } from '../../../utils/containImageStyle';

const StyledWrapper = styled.div({
  textAlign: 'center',
});

type StyledImageProps = {
  $image: string;
};

const StyledImage = styled.div<StyledImageProps>(({ $image }) => ({
  maxWidth: 280,
  height: 205,
  marginBottom: 20,
  marginLeft: 'auto',
  marginRight: 'auto',
  ...containImageStyle($image),

  [CONDITION_DESKTOP]: {
    maxWidth: 300,
    height: 220,
    marginBottom: 20,
  },
}));

const StyledTitle = styled.div({
  fontWeight: 'bold',
  fontSize: 17,
  lineHeight: 1.47,
  color: '#222d39',
  marginBottom: 10,

  [CONDITION_DESKTOP]: {
    fontSize: 19,
    lineHeight: 1.32,
    marginBottom: 15,
  },
});

const StyledDescription = styled.div({
  fontSize: 17,
  fontWeight: 500,
  lineHeight: 1.47,
  color: '#3e4854',
});

type ItemProps = {
  image: string;
  title: string;
  description: string;
};

export const Item: FC<ItemProps> = ({ image, title, description }) => (
  <StyledWrapper>
    <StyledImage $image={image} />

    <StyledTitle>{title}</StyledTitle>

    <StyledDescription>{description}</StyledDescription>
  </StyledWrapper>
);
