import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store/ducks';

import { CategoryModal } from './CategoryModal';

export const CategoryModalWrapper = memo(() => {
  const isVisible = useSelector(selectors.app.selectIsVisibleCategoryModal);
  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    dispatch(actions.app.setIsVisibleCategoryModal(true));
  }, []);

  useEffect(() => {
    if (isVisible) openModal();
  }, [isVisible]);

  const closeModal = useCallback(() => {
    dispatch(actions.app.setIsVisibleCategoryModal(false));
  }, []);

  return <CategoryModal show={isVisible} onHide={closeModal} />;
});
