import { useRouter } from 'next/router';
import React, { memo } from 'react';
import { routes } from 'shared/common/routes';

import { Button } from '../../components/Button';
import { BannerBase } from '../BannerBase';
import { Item } from './Item';

export const BecomeAnExpert = memo(() => {
  const router = useRouter();

  return (
    <BannerBase
      title="Станьте экспертом в Consulty"
      actions={
        <Button
          $block
          isShadow
          onClick={() => {
            router.push(routes.expertLanding);
          }}
        >
          Подробнее
        </Button>
      }
      bubbleImage="/landing/banners/become-an-expert/bubble@2x.png"
      bubbleImageRetina="/landing/banners/become-an-expert/bubble@3x.png"
      mainImageDesktop="/landing/banners/become-an-expert/girl_mask.png"
      mainImageMobile="/landing/banners/become-an-expert/girl.png"
      bgColor="#ffc910"
    >
      <Item>Зарабатывайте на консультациях</Item>

      <Item>Бесплатно привлекайте новых клиентов</Item>

      <Item>Зарабатывайте на партнерской программе</Item>
    </BannerBase>
  );
});
