import { useAppDispatch } from 'hooks/redux';
import { useRouter } from 'next/router';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { routes } from 'shared/common/routes';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { BannerBase } from '../BannerBase';
import { Item } from './Item';

export const FirstConsultation = memo(() => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isAuth = useSelector(selectors.profile.selectIsAuthentication);

  const openRegistration = () => {
    if (isAuth) {
      router.push({ pathname: routes.profile, query: { activeTab: 'expertProfile' } });
    } else {
      dispatch(actions.app.showAuthModal());
    }
  };

  return (
    <BannerBase
      title="Закажите вашу первую консультацию в Consulty"
      actions={
        <Button $block isShadow onClick={openRegistration}>
          Зарегистрироваться
        </Button>
      }
      mainImageDesktop="/landing/banners/first-consult/girl-2@2x.png"
      mainImageMobile="/landing/banners/first-consult/girl-2.png"
      bgColor="#ffc910"
    >
      <Item>
        <ClickText onClick={openRegistration}>Пройдите</ClickText> простую регистрацию за 1 минуту
      </Item>

      <Item>
        <ClickText
          onClick={() => {
            router.push(routes.topics);
          }}
        >
          Выберите тему
        </ClickText>{' '}
        в которой вам нужен эксперт
      </Item>

      <Item>
        Выберите эксперта по отзывам и опыту самостоятельно или задайте ваш вопрос всем экспертам и ждите предложений о
        консультации
      </Item>
    </BannerBase>
  );
});

const ClickText = styled.span({
  textDecoration: 'underline',
  cursor: 'pointer',
});
