import type { ReactNode } from 'react';
import React, { memo, useCallback,useState } from 'react';
import styled from 'styled-components';

import { Container } from '../../../components/Container';
import { CONDITION_DESKTOP } from '../../../constants/breakpoints';
import { Images } from './Images';
import { Tags } from './Tags';
import type { BlockType } from './types';

const StyledContent = styled.div({
  paddingTop: 40,
  paddingBottom: 40,

  [CONDITION_DESKTOP]: {
    paddingTop: 90,
    paddingBottom: 90,
  },
});

const StyledTitle = styled.div({
  fontSize: 24,
  lineHeight: '30px',
  fontWeight: 'bold',
  color: '#232832',
  marginBottom: 20,
  textAlign: 'center',

  [CONDITION_DESKTOP]: {
    fontSize: 40,
    lineHeight: '45px',
    marginBottom: 30,
  },
});

const StyledDescription = styled.h2({
  fontSize: 15,
  lineHeight: '25px',
  fontWeight: 500,
  color: '#79818c',
  marginBottom: 40,
  textAlign: 'center',

  [CONDITION_DESKTOP]: {
    fontSize: 19,
    lineHeight: 1.58,
    marginBottom: 60,
  },
});

const StyledTagsWrapper = styled.div({
  paddingBottom: 40,

  [CONDITION_DESKTOP]: {
    maxWidth: 800,
    paddingBottom: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const StyledImagesWrapper = styled.div({
  position: 'relative',
});

const StyledImagesBlockWrapper = styled.div({
  transition: 'opacity 0.8s',
  opacity: 1,
});

const title = 'Смотрите, как удобно!';
const description =
  'Раньше все искали ответ на сложный вопрос среди знакомых специалистов, либо переворачивали интернет в поисках крупиц полезной информации. Теперь достаточно зайти в Consulty и обратиться к проверенному эксперту — он даст ответ.';

const blocks: BlockType[] = [
  {
    label: 'Спорт',
    icon: '/landing/ball-small.png',
    iconRetina: '/landing/ball-small@2x.png',
    imageDesktop: '/landing/main-promo/b1.png',
    imageMobile: '/landing/main-promo/b1-mobile.png',
  },

  {
    label: 'Путешествия',
    icon: '/landing/trip.png',
    iconRetina: '/landing/trip@2x.png',
    imageDesktop: '/landing/main-promo/b2.png',
    imageMobile: '/landing/main-promo/b2-mobile.png',
  },

  {
    label: 'Юридические вопросы',
    icon: '/landing/urist.png',
    iconRetina: '/landing/urist@2x.png',
    imageDesktop: '/landing/main-promo/b3.png',
    imageMobile: '/landing/main-promo/b3-mobile.png',
  },

  {
    label: 'Бизнес',
    icon: '/landing/bussines.png',
    iconRetina: '/landing/bussines@2x.png',
    imageDesktop: '/landing/main-promo/b4.png',
    imageMobile: '/landing/main-promo/b4-mobile.png',
  },

  {
    label: 'Выбор техники',
    icon: '/landing/tech.png',
    iconRetina: '/landing/tech@2x.png',
    imageDesktop: '/landing/main-promo/b5.png',
    imageMobile: '/landing/main-promo/b5-mobile.png',
  },

  {
    label: 'Финансы',
    icon: '/landing/finans.png',
    iconRetina: '/landing/finans@2x.png',
    imageDesktop: '/landing/main-promo/b6.png',
    imageMobile: '/landing/main-promo/b6-mobile.png',
  },
];

export const Promo = memo(() => {
  const [[activeIndex, prevActiveIndex], setIndexes] = useState<[number, number | null]>([0, null]);

  const setActiveIndex = useCallback((nextIndex: number): void => {
    setIndexes((prevStateValue) => {
      const [index] = prevStateValue;

      if (nextIndex === index) {
        return prevStateValue;
      }

      return [nextIndex, index];
    });
  }, []);

  const renderedImages: ReactNode[] = [
    <StyledImagesBlockWrapper key={activeIndex}>
      <Images block={blocks[activeIndex]} />
    </StyledImagesBlockWrapper>,
  ];

  if (prevActiveIndex !== null) {
    renderedImages.push(
      <StyledImagesBlockWrapper
        style={{
          position: 'absolute',
          zIndex: 5,
          top: 0,
          left: 0,
          width: '100%',
          opacity: 0,
        }}
        key={prevActiveIndex}
      >
        <Images block={blocks[prevActiveIndex]} />
      </StyledImagesBlockWrapper>,
    );
  }

  return (
    <Container>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>

        <StyledDescription>{description}</StyledDescription>

        <StyledTagsWrapper>
          <Tags blocks={blocks} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
        </StyledTagsWrapper>

        <StyledImagesWrapper>{renderedImages}</StyledImagesWrapper>
      </StyledContent>
    </Container>
  );
});
