import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../../constants/breakpoints';
import type { BlockType } from '../types';
import { Tag } from './Tag';

const StyledList = styled.div({
  marginTop: 30,
  marginRight: -10,
  marginBottom: -15,

  [CONDITION_DESKTOP]: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 40,
    marginRight: -20,
    marginBottom: -20,
  },
});

type TagsProps = {
  blocks: BlockType[];
  activeIndex: number;

  setActiveIndex: (index: number) => void;
};

export const Tags: FC<TagsProps> = ({ blocks, activeIndex, setActiveIndex }) => {
  if (blocks.length === 0) {
    return null;
  }

  return (
    <StyledList>
      {blocks.map((block, index) => (
        <Tag block={block} index={index} activeIndex={activeIndex} setActiveIndex={setActiveIndex} key={index} />
      ))}
    </StyledList>
  );
};
