import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { CONDITION_RETINA } from '../../../../constants/breakpoints';
import { containImageStyle } from '../../../../utils/containImageStyle';
import type { ExpertType } from './types';

const StyledWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',

  '& + &': {
    marginTop: 40,
  },
});

const StyledAvatarWrapper = styled.div({
  paddingRight: 20,
});

const StyledContent = styled.div({
  flex: 1,
});

type StyledAvatarProps = {
  $userpic?: string;
  $userpicRetina?: string;
};

const StyledAvatar = styled.div<StyledAvatarProps>(({ $userpic, $userpicRetina }) => ({
  width: 90,
  height: 90,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  borderBottomLeftRadius: 20,
  ...containImageStyle($userpic || null),
  backgroundColor: '#eee',

  [CONDITION_RETINA]: {
    backgroundImage: `url(${$userpicRetina})`,
  },
}));

const StyledName = styled.div({
  fontSize: 15,
  fontWeight: 700,
  lineHeight: '20px',
  color: '#232832',
  marginBottom: 2,
});

/* const StyledNameLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
}); */

const StyledCategory = styled.div({
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 1.15,
  color: '#232832',
});

const StyledRating = styled.div({
  position: 'relative',

  fontWeight: 700,
  fontSize: 17,
  lineHeight: 1.18,
  color: '#232832',
  marginTop: 10,
  paddingLeft: 22,

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 15,
    height: 15,
    ...containImageStyle('/landing/on.svg'),
  },
});

type StyledAchievementProps = {
  $achievementIcon: string;
};

const StyledAchievement = styled.div<StyledAchievementProps>(({ $achievementIcon }) => ({
  position: 'relative',

  fontSize: 13,
  lineHeight: '15px',
  fontWeight: 500,
  color: '#8b33ff',
  paddingLeft: 20,
  marginBottom: 11,

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 15,
    height: 15,
    ...containImageStyle($achievementIcon),
  },
}));

type ExpertProps = {
  expert: ExpertType;
};

export const Expert: FC<ExpertProps> = ({
  expert: {
    // id,
    first_name: firstName,
    last_name: lastName,
    rating,
    userpic,
    userpicRetina,
    achievement,
    achievementIcon,
  },
}) => (
  <StyledWrapper>
    <StyledAvatarWrapper>
      {/* <Link
        to={url}
      >
        <StyledAvatar
          $userpic={userpic}
          $userpicRetina={userpicRetina}
        />
      </Link> */}

      <StyledAvatar $userpic={userpic} $userpicRetina={userpicRetina} />
    </StyledAvatarWrapper>

    <StyledContent>
      <StyledAchievement $achievementIcon={achievementIcon}>{achievement}</StyledAchievement>

      <StyledName>
        {/* <StyledNameLink
          to={url}
        >
          {firstName}
          {' '}
          {lastName}
        </StyledNameLink> */}
        {firstName} {lastName}
      </StyledName>

      <StyledCategory>Бизнес</StyledCategory>

      <StyledRating>{rating}</StyledRating>
    </StyledContent>
  </StyledWrapper>
);
