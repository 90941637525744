import type { FC } from 'react';
import React from 'react';
import { Container } from 'routes/landing/components/Container';
import styled from 'styled-components';

export const Experts: FC = () => (
  <Container>
    <Wrapper>
      <StyledTitle>Более 2000 экспертов</StyledTitle>
      <StyledDescription>
        В Consulty уже более 2000 экспертов в теме бизнес.
      </StyledDescription>
    </Wrapper>
  </Container>
);

const Wrapper = styled.div({
  width: '100%',
  textAlign: 'center',
  borderRadius: 6,
  backgroundColor: '#ff3366',
  paddingTop: 15,
  paddingBottom: 15,
});

const StyledTitle = styled.span({
  color: '#ffff',
  fontWeight: 'bold',
  fontSize: 17,
  lineHeight: 1.47,
});

const StyledDescription = styled.div({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 1.47,
  color: '#ffff',
});
