import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { CONDITION_DESKTOP } from '../../../../constants/breakpoints';
import { Expert } from './Expert';
import type { ExpertType } from './types';

const StyledTitle = styled.div({
  fontSize: 21,
  fontWeight: 'bold',
  lineHeight: 1,
  color: '#232832',
  marginBottom: 30,

  [CONDITION_DESKTOP]: {
    fontSize: 25,
    lineHeight: 1.2,
    marginBottom: 40,
  },
});

const experts: ExpertType[] = [
  {
    id: 1,
    first_name: 'Никита',
    last_name: 'Толстов',
    rating: '4.95',
    comments_count: '',
    about: '',
    average_price: '',
    achievement: 'Востребованный',
    achievementIcon: '/landing/flame.svg',
    userpic: '/landing/experts/expert1.png',
    userpicRetina: '/landing/experts/expert1@2x.png',
  },

  {
    id: 2,
    first_name: 'Вадим',
    last_name: 'Кочетков',
    rating: '4.95',
    comments_count: '',
    about: '',
    average_price: '',
    achievement: 'С лучшими отзывами',
    achievementIcon: '/landing/like-small.svg',
    userpic: '/landing/experts/expert2.png',
    userpicRetina: '/landing/experts/expert2@2x.png',
  },
];

export const Experts: FC = () => (
  <div>
    <StyledTitle>Топовые эксперты</StyledTitle>

    <div>
      {experts.map((expert) => (
        <Expert expert={expert} key={expert.id} />
      ))}
    </div>
  </div>
);
