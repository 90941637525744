import { useRouter } from 'next/router';
import React, { memo } from 'react';
import ym from 'react-yandex-metrika';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Container } from '../../../components/Container';
import { VideoButton } from '../../../components/VideoButton';
import { CONDITION_DESKTOP, DESKTOP } from '../../../constants/breakpoints';
import { MessagesExample } from './MessagesExample';
import { Texts } from './Texts';

const StyledWrapper = styled.div({
  paddingTop: 40,
  paddingBottom: 60,
  backgroundImage: 'linear-gradient(to right,#862fff 10%,#9c3efe 40%,#AE4CFE 90%)',

  [CONDITION_DESKTOP]: {
    overflow: 'hidden',
    minWidth: DESKTOP,
    paddingTop: 80,
    paddingBottom: 80,
  },
});

const StyledContent = styled.div({
  [CONDITION_DESKTOP]: {
    display: 'flex',
  },
});

const StyledLeftBlock = styled.div({
  [CONDITION_DESKTOP]: {
    boxSizing: 'content-box',
    width: 600,
    paddingRight: 80,
    paddingTop: 10,
  },
});

const StyledRightBlock = styled.div({
  marginTop: 50,

  [CONDITION_DESKTOP]: {
    marginTop: 10,
  },
});

const StyledTitle1 = styled.div({
  fontSize: 25,
  fontWeight: 300,
  lineHeight: 1.2,
  color: '#ffffff',
  marginBottom: 10,

  [CONDITION_DESKTOP]: {
    marginBottom: 5,
    fontSize: 40,
    lineHeight: 1.13,
  },
});

const StyledDescription = styled.h1({
  fontSize: 17,
  fontWeight: 500,
  lineHeight: 1.47,
  color: '#ffffff',

  [CONDITION_DESKTOP]: {
    fontSize: 19,
    lineHeight: 1.58,
  },
});

const StyledButtonsBlock = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 40,

  [CONDITION_DESKTOP]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 50,
  },
});

const StyledButtonWrapper = styled.div({
  '& + &': {
    marginTop: 40,
  },

  [CONDITION_DESKTOP]: {
    '& + &': {
      marginTop: 0,
      marginLeft: 40,
    },
  },
});

const StyledMessagesWrapper = styled.div({});

const title1 = 'Найдем нужного вам эксперта';
const description =
  'Consulty – простой сервис, в котором можно найти эксперта в любой области и заказать у него консультацию по вашему вопросу.';

const sendDataToMetrics = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['Landing_youtube']);
    if (window && window.gtag) {
      window.gtag('event', 'Landing_youtube');
    }
    // window.fbq('trackCustom', 'Landing_youtube');
  }
};

export const TopBlock = memo(() => {
  const router = useRouter();

  const handleNavigateStandExpert = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      ym('reachGoal', ['Main-become_expert-more_info']);
      if (window && window.gtag) {
        window.gtag('event', 'Main-become_expert-more_info');
      }
      // window.fbq('trackCustom', 'Main-become_expert-more_info');
    }
    router.push(routes.expertLanding);
  };

  return (
    <StyledWrapper>
      <Container>
        <StyledContent>
          <StyledLeftBlock>
            <StyledTitle1>{title1}</StyledTitle1>
            <Texts />
            <StyledDescription>{description}</StyledDescription>
            <StyledButtonsBlock>
              <StyledButtonWrapper>
                <Button type="button" $color="white" $size="big" onClick={handleNavigateStandExpert}>
                  Стать экспертом Consulty
                </Button>
              </StyledButtonWrapper>
              <StyledButtonWrapper onClick={sendDataToMetrics}>
                <VideoButton url="https://www.youtube.com/embed/-zNna5RD77U">
                  Посмотрите, как работает сервис
                </VideoButton>
              </StyledButtonWrapper>
            </StyledButtonsBlock>
          </StyledLeftBlock>
          <StyledRightBlock>
            <StyledMessagesWrapper>
              <MessagesExample />
            </StyledMessagesWrapper>
          </StyledRightBlock>
        </StyledContent>
      </Container>
    </StyledWrapper>
  );
});
